<template>
  <div class="CharaterSet">
    <div class="CharaterSet-box">
      <div class="charaterSet-box-left">
        <div class="charaterSet-role">
          <el-input type="text" placeholder="搜索角色组、角色、人员" v-model="filterText"></el-input>
        </div>
        <div class="tree_form">
          <el-tree :data="data" @node-click='getroli' node-key="id" :props="defaultProps" default-expand-all
            :expand-on-click-node="false" :filter-node-method="filterNode" ref="tree" :render-content="renderContent">
          </el-tree>
        </div>
        <div class="newly">
          <a @click="drawerS = true ">新增角色组</a>
          <a @click="drawerX = true">新增角色</a>
        </div>
      </div>
      <div class="charaterSet-box-right">
        <div class="charaterSet-tltle">
          <p> 角色:  {{MBX}}</p>

          <a @click="pero=[];seleRole=[];drawer = true;"><i></i><span>增加角色人员</span></a>
        </div>
        <div class="charaterSet-ul">
          <table class="charaterSet-table">
            <tr class="charaterSet-table-tr">
              <td>头像</td>
              <td>姓名</td>
              <td>角色</td>
              <td>部门</td>
              <td>所属门店</td>
              <td>操作</td>
            </tr>
            <tr v-for="(item,index) in items" :key="index">
              <td><el-avatar>{{item.staff_name}}</el-avatar></td>
              <td>{{item.staff_name}}</td>
              <td>{{item.role_name}}</td>
                <td>{{item.dept_name}}</td>

              <td >{{filterName(item.shop_name)}}</td>
              <td ><span @click="delrole(item)">删除</span></td>
            </tr>

          </table>
        </div>
      </div>
    </div>

    <el-drawer :visible.sync="drawer" ref="drawer" :with-header="false" class="">
      <div class="newRole">
        <div class="Character-tltle">
          <span></span>
          <p>新增角色人员</p>
        </div>
      </div>


      <el-cascader v-model="pero" style="margin-top:20px;margin-left:20px;" :options="peroList" :props="defaultProps2" placeholder="请选择员工"></el-cascader>
      <p></p>

      <el-cascader style="margin-top:20px;margin-left:20px;" v-model="seleRole" :options="data" placeholder="请选择角色" :props="defaultProps"></el-cascader>

    <br>
      <el-button type="primary" style="margin-top:20px;margin-left:20px;" plain @click="addPeroSave">保存</el-button>
    </el-drawer>

    <!-- 新增角色组 -->
    <el-drawer title="新增角色组" :visible.sync="drawerS" ref="drawerS" :with-header="false">
      <div class="Character">
        <div class="Character-tltle">
          <span></span>
          <p>新增角色组</p>
        </div>
        <div class="Character-name">
          <p><span>*</span>角色组名称</p>
          <el-input v-model="part" type="text" placeholder="请输入"></el-input>
        </div>
        <div class="admin-alter">
          <a class="admin-confirm" @click="AddRess">确定</a>
          <a class="admin-cancel" @click="cancel">取消</a>
        </div>
      </div>
    </el-drawer>

     <!-- 编辑角色组 -->
    <el-drawer title="编辑角色组" :visible.sync="drawerSE" ref="drawerS" :with-header="false">
      <div class="Character">
        <div class="Character-tltle">
          <span></span>
          <p>编辑角色组</p>
        </div>
        <div class="Character-name">
          <p><span>*</span>角色组名称</p>
          <el-input v-model="Oda.role_name" type="text" placeholder="请输入"></el-input>
        </div>
        <div class="admin-alter">
          <a class="admin-confirm" @click="AddRessE">确定</a>
          <a class="admin-cancel" @click="drawerSE=false">取消</a>
        </div>
      </div>
    </el-drawer>

    <!-- 新增角色 -->
    <el-drawer title="新增角色" :visible.sync="drawerX" ref="drawerX" :wrapperClosable='false' :with-header="false">
      <div class="CharacterS">
        <div class="Character-tltle">
          <span></span>
          <p>新增角色</p>
        </div>
        <div class="Character-name">
          <p><span>*</span>角色名称：</p>
          <el-input type="text" placeholder="请输入" v-model="addRoleValue"></el-input>
        </div>
        <div class="Character-name">
          <p><span>*</span>分至角色组：</p>
          <el-select v-model="addRole" placeholder="请选择">
            <el-option v-for="item in data" :key="item.value" :label="item.role_group_name" :value="item.role_group_id">
            </el-option>
          </el-select>
          <!-- <el-cascader v-model="addRole" :options="data"  :props="defaultProps"   :show-all-levels="false"></el-cascader> -->
        </div>
        <div class="admin-alter">
          <a class="admin-confirm" @click="confirmS">确定</a>
          <a class="admin-cancel" @click="cancelS">取消</a>
        </div>
      </div>
    </el-drawer>

      <!-- 新增角色 -->
    <el-drawer title="编辑角色" :visible.sync="drawerXs" ref="drawerX" :with-header="false">
      <div class="CharacterS">
        <div class="Character-tltle">
          <span></span>
          <p>编辑角色</p>
        </div>
        <div class="Character-name">
          <p><span>*</span>角色名称：</p>
          <el-input type="text" placeholder="请输入" v-model="Od.role_name"></el-input>
        </div>
        <div class="Character-name">
          <p><span>*</span>分至角色组：</p>
          <el-select v-model="Od.role_group_id" placeholder="请选择">
            <el-option v-for="item in data" :key="item.value" :label="item.role_group_name" :value="item.role_group_id">
            </el-option>
          </el-select>

        </div>
        <div class="admin-alter">
          <a class="admin-confirm" @click="cancelSeq">确定</a>
          <a class="admin-cancel" @click="drawerXs=false">取消</a>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
// import { delete } from 'vue/types/umd'
  import {
    insert_role_group_info,
    query_role_group_info_list,
    delete_role_group_info,
    insert_role_info,
    query_dept_staff_tree_aim_not,
    set_staff_role_rel,
    query_user_list_of_role,
    delete_role_info,

  } from '../../api/store'
  import {update_role_group_info,update_role_info,delete_staff_role_rel} from '../../api/public'
  let id = 1000
  export default {
    data() {

      return {
        MBX:'',
        Od:{},
        Oda:{},
        drawerXs:false,
        drawerSE:false,
        name: '',
        drawer: false,
        drawerS: false,
        drawerX: false,
        soo: true,
        part: '',
        defaultProps: {
          children: 'role_list',
          label: 'role_name',
          value: 'role_id'
        },
        defaultProps2: {
          multiple: true,
          children: 'children',
          label: 'label',
          value: 'id'
        },
        options: [],
        value: '',
        data: [],
        addRoleValue: '',
        addRole: '',
        filterText: '',
        peroList: [],
        pero: [],
        seleRole: [],
        items: [],
        nowrolerole_id:''
      }
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    created() {
      this.GetRoleList()
      this.GetPeroList()
      this.GetroleL()

    },

    mounted(){
      // this.GetRoleList()
      // this.GetPeroList()
      // this.GetroleL()
    },

    methods: {
      filterName(name){
        if(name instanceof Array){
          return name.join(',')
        }else{
          return name
        }
      },
      delrole(item){
        console.log('========================');
        delete_staff_role_rel({
          data: {
            ent_id: this.$store.state.ent_id,
            // role_id: this.Od.role_id,
            role_id: item.role_id,
            staff_ids: item.user_id,
            user_id:this.$store.state.user_id
          }
        }).then(res => {
         if(res.code == '300'){
           this.$message({
              message: '您不能删除主管理员,子管理员及主管',
              type: 'error',
            })
         }
          if (res.code == '200') {
             this.$confirm('此操作将该人员删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.drawerXs=false
                this.GetRoleList()
                this.GetPeroList()
                this.getnowrole()
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                });
              });

          }
        })
      },
      // delete_staff_role_rel
      // update_role_info
      cancelSeq(){
         update_role_info({
          data: {
            ent_id: this.$store.state.ent_id,
            role_id: this.Od.role_id,
            role_group_id:this.Od.role_group_id,
            role_name:this.Od.role_name
          }
        }).then(res => {
          if (res.code == '200') {
             this.$message({
              message: '更新角色成功',
              type: 'success',
            })
            this.drawerXs=false

              this.GetRoleList()
              this.GetPeroList()
              this.GetroleL()
              this.getnowrole()
          }
          if (res.code =='1200'||res.code =='1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })
      },
      AddRessE(){
      update_role_group_info({
          data: {
            ent_id: this.$store.state.ent_id,
            role_id: this.Oda.role_id,
            role_group_id:this.Oda.role_group_id,
            role_group_name:this.Oda.role_name
          }
        }).then(res => {
          if (res.code == '200') {
             this.$message({
              message: '更新角色组成功',
              type: 'success',
            })
            this.drawerSE=false
              this.GetRoleList()
              this.GetPeroList()
              this.GetroleL()
          }
          if (res.code =='1200'||res.code =='1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })
      },
      EditZU(node,data){
        if(data.role_list!=undefined){
          this.Oda=JSON.parse(JSON.stringify(data))
          console.log(data)
            this.drawerSE=true
        }else{
            this.Od=JSON.parse(JSON.stringify(data))
             this.drawerXs=true
        }
      },
      getroli(data, node, val) {
          //
          // return


        if(data.role_list!=undefined){

        }else{
          this.MBX=data.role_name
          this.nowrolerole_id=data.role_id
           query_user_list_of_role({
          data: {
            ent_id: this.$store.state.ent_id,
            role_id: data.role_id
          }
        }).then(res => {
          if (res.code == '200') {






            this.items = res.body.data
            console.log(this.items)

            //
          }
        })
        }



      },
      getnowrole(){
     query_user_list_of_role({
          data: {
            ent_id: this.$store.state.ent_id,
            role_id: this.nowrolerole_id
          }
        }).then(res => {
          if (res.code == '200') {

            let arr=res.body.data
           for (const i in arr) {
            console.log(arr[1],'kkkk')
           }


            this.items = res.body.data
            console.log(this.items)

            // 
          }
        })
      },
      GetroleL() {

        query_user_list_of_role({
          data: {
            ent_id: this.$store.state.ent_id,
          }
        }).then(res => {
          if (res.code == '200') { 
            let arr=res.body.data
            for (const i in arr) {
             if(arr[i].role_name=='主管理员'){
              arr.unshift(arr.splice(i , 1)[0]);
             }
            }
            this.items =arr
            console.log(this.items)

            //
          }
        })
      },
      addPeroSave() {

          if(this.seleRole.length==0||this.pero.length==0){
               this.$message({
              message: '请输入完整信息',
              type: 'error',

            })
            return
          }



         let arr=[]
         let arr2=[]
          arr=this.pero.flat(Infinity)

          for (const i in arr) {

            if (arr[i][0] == 'Y') {
              arr2.push(arr[i])
            }
          }
        set_staff_role_rel({
          data: {
            ent_id: this.$store.state.ent_id,
            role_id: this.seleRole[1],
            staff_ids: arr2.join(',')
          }
        }).then(res => {
          if (res.code == '200') {
              this.drawer=false
            this.$message({
              message: '添加角色成功',
              type: 'success',

            })
            this.drawerX=false
              // this.GetRoleList()
            this.GetRoleList()

            this.GetPeroList()
            // this.GetroleL()
            this.getnowrole()
            // 
          }else if (res.code == '12800') {
            this.$message({
              message: '该角色不能添加人员',
              type: 'error',

            })
          }else{
             this.$message({
              message: '添加角色失败',
              type: 'error',

            })
          }
        })
      },
      GetPeroList() {

        query_dept_staff_tree_aim_not({
          data: {
            ent_id: this.$store.state.ent_id,
          }
        }).then(res => {
          if (res.code == '10168') {
            this.peroList = res.body.data

            console.log(res.body.data)
            // for (let item of res.body.data) {
              //
              // for (let item2 of item.children) {
              //   if(item2.type=='2'){
              //      delete  item2.children
              //   }
              // }
          // }


            //
            // this.peroList = res.body.data
            //
          }
        })
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.role_name.indexOf(value) !== -1;
      },
      GetRoleList() {
        query_role_group_info_list({
          data: {
            ent_id: this.$store.state.ent_id,
            active: '1'
          }
        }).then(res => {
          if (res.code == '200') {
            console.log(res.body.data,'角色列表')
            for (const i in res.body.data) {
              res.body.data[i].role_name = res.body.data[i].role_group_name
            }


            this.data = res.body.data

          }
        })

      },
      AddRess() {

        if (!this.part) {
          this.$message({
            message: '请输入角色组名称',
            type: 'warning',

          })
        }
        insert_role_group_info({
          data: {
            ent_id: this.$store.state.ent_id,
            user_id: this.$store.state.user_id, // 用户id
            role_group_name: this.part
          }
        }).then(res => {
          if (res.code == 200) {
           this.part = ''
            this.drawerS = false
            console.log(res)
            if (res.code == '200') {
              this.$message({
                message: '新增角色组成功',
                type: 'success',
              })
              this.GetRoleList()
            }
          }
          if (res.code =='1200'||res.code =='1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })
      },
      search() {
        if (this.name === '') {
          this.$message({
            message: '搜索不能为空',
            type: 'warning',
            center: true
          })
        }
      },
      gaibian() {
        console.log(1110)
      },
      handleNodeClick(data) {
        console.log(data)
      },
      // 新增角色组
      confirm() {
        this.drawerS = false
      },
      cancel() {
        this.drawerS = false
      },
      // 新增角色
      confirmS() {

        insert_role_info({
          data: {
            ent_id: this.$store.state.ent_id,
            role_group_id: this.addRole,
            user_id: this.$store.state.user_id,
            role_name: this.addRoleValue
          }
        }).then(res => {
          if (res.code == '200') {
            this.$message({
              message: '新增角色成功',
              type: 'success',
            })

               this.drawerX = false

            //  this.GetRoleList()
      // this.GetPeroList()
      // this.GetroleL()
            this.GetRoleList()
          }else if(res.code == '600'){
            this.$message({
              message: '已有角色',
              type: 'error',
            })
          }else if(res.code == '12900'){
            this.$message({
              message: '默认角色组不能添加角色',
              type: 'error',
            })
          }else if (res.code =='1200'||res.code =='1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })
        this.drawer = false
        this.addRole = ''
        this.addRoleValue= ''
      },
      cancelS() {
        this.drawerX = false
        this.addRole = ''
        this.addRoleValue= ''

      },
      append(data) {
        const newChild = {
          id: id++,
          label: 'testtest',
          children: []
        }
        if (!data.children) {
          this.$set(data, 'children', [])
        }
        data.children.push(newChild)
      },

      remove(node, data) {
        // 
        if(data.role_group_id=='default_role_group'){
          this.$message({
              message: '默认角色不能删除哟',
              type: 'warning',
              center: true
            })

            return
        }

        if(data.role_list!=undefined){

          if(data.role_list.length!=0){
            this.$message({
              message: '角色组下有角色，不能删除哦',
              type: 'warning',
              center: true
            })
            return
          }
          delete_role_group_info({
          data: {
            ent_id: this.$store.state.ent_id,
            role_group_ids: data.role_group_id,
            user_id: this.$store.state.user_id
          }
        }).then(res => {
          if (res.code == '200') {
            this.$message({
              message: '已删除角色组',
              type: 'warning',
              center: true
            })
            this.GetRoleList()
          }
        })
        }else{
       delete_role_info({
          data: {
            ent_id: this.$store.state.ent_id,
            role_ids: data.role_id,
            user_id: this.$store.state.user_id
          }
        }).then(res => {
          if (res.code == '200') {
            this.$message({
              message: '已删除角色组',
              type: 'warning',
              center: true
            })
            this.GetRoleList()
          }
          if (res.code == '13000'){
            this.$message({
               message: '角色下有人员，不能删除角色！',
               type: 'warning',
               center: true
             })
          }
        })
        }

        // const parent = node.parent
        // const children = parent.data.children || parent.data
        // const index = children.findIndex(d => d.id === data.id)
        // children.splice(index, 1)

      },
      draweR(node, data) {
        this.drawer = true
      },
      renderContent(h, { node, data, store }) {
        if(data.staff_num){
          return (
        <span class='custom-tree-node'>
          <span>{node.label}</span> <span v-show='data.role_name'>({data.staff_num})</span>
          <span>
            <el-button
              size='mini'
              type='text'
              on-click={() => this.EditZU(node, data)}
            >
              {' '}
              <i></i>
            </el-button>
            <el-button
              size='mini'
              type='text'
              on-click={() => this.remove(node, data)}
            >
              <i></i>
            </el-button>
          </span>
        </span>
      )
        }else{
          return (
        <span class='custom-tree-node'>
          <span>{node.label}</span>
          <span>
            <el-button
              size='mini'
              type='text'
              on-click={() => this.EditZU(node, data)}
            >
              {' '}
              <i></i>
            </el-button>
            <el-button
              size='mini'
              type='text'
              on-click={() => this.remove(node, data)}
            >
              <i></i>
            </el-button>
          </span>
        </span>
      )
        }

    }

    }
  }

</script>

<style scoed lang="scss">
  .el-main {

    background: #f6f6fa;
  }

  .CharaterSet {
    width: 100%;
    margin-top: 24px;
    .tree_form{
      height: 500px;
      overflow-y:auto;
      &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
            background-color: #f3f3f3;
        }
        /*定义滚动条轨道 内阴影+圆角*/
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px #f3f3f3;
            border-radius: 3px;
            background-color: #f3f3f3;
        }
        /*定义滑块 内阴影+圆角*/
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            box-shadow: inset 0 0 0px #b9b8b8;
            background-color: #b9b8b8;
        }
    }
    .CharaterSet-box {
      display: flex;
    }

    .charaterSet-box-left {
      background: #ffffff;
      width: 20%;
      // height: 600px;
      padding: 30px 0 30px 0;
      display: block;

      .charaterSet-role {
        position: relative;

        i {
          position: absolute;
          width: 22px;
          height: 22px;
          display: block;
          background: url('../../assets/img/shou.png') no-repeat;
          background-size: 22px 22px;
          right: 50px;
          top: 10px;
        }

        input {
          width: 100%;
        }
      }

      .newly {
        display: flex;
        justify-content: center;
        margin-top: 0; // 90px;
        & a:first-of-type {
          margin-right: 20px;
        }

        a {
          width: 100px;
          height: 36px;
          background: #fc4353;
          line-height: 36px;
          text-align: center;
          display: block;
          border-radius: 10px;
          font-size: 16px;
          color: #fff;
        }
      }
    }

    .charaterSet-box-right {
      margin-left: 20px;
      width: 80%;
      height: 855px;
      background: #ffffff;
      border-radius: 10px;

      .charaterSet-tltle {
        display: flex;
        justify-content: space-between;
        padding: 0 40px;
        height: 70px;

        p {
          font-size: 16px;
          color: #333333;
          line-height: 70px;
          span{
            color: #333;
            font-size: 16px;
          }
        }

        a {
          width: 129px;
          height: 40px;
          background: #ff404e;
          border-radius: 10px;
          color: #fff;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 40px;
          margin-top: 14px;
          position: relative;
          display: flex;
          justify-content: center;

          span {
            margin-left: 10px;
          }

          i {
            width: 15px;
            height: 15px;
            background: url('../../assets/img/fabu.png') no-repeat;
            background-size: 15px 15px;
            margin: 12px 0 0 0;
            display: block;
          }
        }
      }

      .charaterSet-ul {
            width: 100%;
            height: 500px;
            overflow: auto;
        .charaterSet-table {
          width: 100%;

          &:first-of-type .charaterSet-table-tr {
            background: #eeeef3;
            border-bottom: 0 #eeeef3 solid;

            td {
              color: #666666;
              font-weight: 600;
            }
          }

          tr {
            width: 100%;
            height: 70px;
            display: flex;
            border-bottom: 1px #eeeef3 solid;

            td {
              line-height: 60px;
              font-size: 16px;
              color: #333333;
              width: 25%;
              text-align: center;
              overflow-y: auto;
              &:nth-child(4) {
                width: 500px;
              }

              img {
                margin-top: 14px;
              }
            }
          }
        }
      }

      .order {
        span {
          font-size: 20px;
          color: red;
        }
      }
    }
  }

  .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .addr {
        color: #ddd;
      }
    }
  }
.newRole{
  // padding: 0 20px;
}
.el-avatar{
  vertical-align: middle;
}
</style>
